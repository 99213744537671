<template>
  <v-app id="inspire" style="background-color: #efeff7">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="3" sm="6" xs="12">
          <v-card outlined elevation="15">
            <v-toolbar color="#1B1E2B" dark flat>
              <v-toolbar-title
                color="primary elevation-0 white--text font-weight-bold"
                >메타베이 관리자 로그인</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              <v-form @keyup.native.enter="submit()">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="아이디"
                  prepend-inner-icon="mdi-account"
                  required
                />

                <v-text-field
                  v-model="password"
                  :type="passEye ? 'text' : 'password'"
                  :rules="passwordRules"
                  :append-icon="passEye ? 'mdi-eye' : 'mdi-eye-off'"
                  label="비밀번호"
                  prepend-inner-icon="mdi-lock"
                  required
                  @click:append="passEye = !passEye"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="validated"
                color="#0D0F16"
                class="subtitle-2 white--text ml-auto"
                @click="submit()"
                >로그인
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      emailRules: [(v) => !!v || "아이디를 입력해 주세요."],
      password: "",
      passwordRules: [(v) => !!v || "비밀번호를 입력해 주세요."],
      passEye: false,
    };
  },
  computed: {
    validated() {
      return this.email === "" || this.password === "" ? true : false;
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async submit() {
      this.login({
        email: this.email,
        password: this.password,
      }).then(() => {
        this.$router.replace({ name: "DashBoard" });
      });
    },
  },
};
</script>
